html {
    overflow-y: scroll;
}

body, table {
    font-size: 12px;
    font-family: Arial;
}
body {
    margin: 0;
    background:#EEEEEE;
}
.page-container {
    max-width: 960px;
    margin: 0 auto;
    box-shadow: 0px 0px 32px #333333;
    background: white;
    
}
#content {
    margin: 0 24px 8px;
}
a {
    color: #00827B;
}
ul {
    line-height: 2em;
    list-style-type: square;
}
.glossary li.term a {
    font-weight: bold;
}
.glossary p {
    margin: 0;
    line-height: 2em;
}
ol.videos {
    line-height: 1.8em;
}
ol.videos li {
    margin-bottom: 1em;
}
ol.videos a {
    font-weight: bold;
}
p.address {
    line-height: 2em;
}
.map-image {
    float: right;
    width: 500px;
    height: 300px;
    margin-bottom: 16px;
}
.videocontainer {
    background: #000000;
    padding: 16px;
}
.videoplayer {
    margin: 0 auto;
    width: 500px;
    height: 300px;
    display: block;
}
.call-out-box {
    border-radius: 6px;
    background-color: #EEEEEE;
    padding: 15px 30px;
    float: right;
    width: 210px;
    margin: 0 0 16px 16px;
    line-height: 2em;
}
.call-out-box h3 {
    margin-bottom: 0;
}
.call-out-box ul {
    padding-left: 20px;
}
.btn {
    text-decoration: none;
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    border: 1px solid #bbbbbb;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-bottom-color: #a2a2a2;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.btn-large {
    padding: 11px 19px;
    font-size: 17.5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
.btn-red {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #da4f49;
    background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
    background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
    background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
    background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
    background-repeat: repeat-x;
    border-color: #bd362f #bd362f #802420;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.photo {
    float: right; 
    margin: 8px;
}
