.date {
    margin-top: 32px;
    color: #CCCCCC;
}
.footer {
    clear: both;
    height: 136px;
    border-top: 1px solid #DEDEDE;
}
.contact-info {
    float: right;
    padding: 0 8px;
    margin-top: 8px;
    border-left: 1px solid #DEDEDE;
    width: 200px;
    line-height: 2em;
    height: 120px;
}
.contact-info span {
    float: left;
    width: 75px;
}
.social-media {
    float: right;
    margin-top: 8px;
    border-left: 1px solid #DEDEDE;
    width: 224px;
    height: 120px;
}
.social-media ul {
    list-style-type: none;
    margin: 8px;
    padding: 0;
}
.social-media ul li {
    float: left;

    margin: 8px;
    background: no-repeat top left;
}
.social-media ul li a {
    width: 32px;
    height: 32px;
    text-indent: -10000px;
    overflow: hidden;
    display: block;
}
.social-media .facebook {
    background: url('/img/icons/social.png') -32px 0px;
}
.social-media .linkedin {
    background: url('/img/icons/social.png') -64px 0px;
}
.social-media .email {
    background: url('/img/icons/social.png');
}
.footer-menu ul {
    padding: 8px 0;
    margin: 0;
    height: 1.2em;
    list-style-type: none;
}
.footer-menu ul li {
    float: left;
    border-left: 1px solid #DEDEDE;
    padding-left: 8px;
    margin-left: 8px;
}
.footer-menu ul li:first-child {
    border-left: 0;
}
.footer .copyright {
    clear: left;
    margin-left: 16px;
}