.form-submit {
    width: 260px;
    float: right;
    margin: 0 0 8px 8px;
    line-height: 1.5em;
}
.form-submit h3 {
    border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    margin: 0;
    background: #00827B;
    color: white;
    padding: 8px;
    letter-spacing: 1px;
    text-shadow: 1px 1px #666666;
}
.form-submit .fields {
    padding: 8px;
    border: 1px solid #DEDEDE;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;

}
.form-submit p {
    margin: 4px 0;
}
.text {
    clear: left;
    padding-top: 8px;
}
.text label {
    width: 80px;
    display: block;
    color: #666666;
}
.text input {
    width: 235px;
    font-size: 18px;
}
.textarea textarea {
    width: 235px;
    max-width: 235px;
    height: 150px;
}
.submit {
    padding-top: 8px;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
}
input.requiredEmpty {
    background-color: #FF9999;
}