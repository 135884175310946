.header {
    width: 960px;
    height: 268px;
    background: url('/img/header.jpg') no-repeat;
    border-bottom: 1px solid black;
}
.header-quote {
    float: right;
    width: 390px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    margin: 24px 170px 0 0;
    line-height: 1.5em;
    text-align: center;
}
.header .mobile-image {
    display: none;
}
.header-menu {
    padding: 210px 0 0;
}
.header-menu ul {
    max-width: 750px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 1.1em;
    clear: both;
}
.header-menu ul li {
    float: left;
}
.header-menu ul li a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    display: block;
    padding: 16px 18px;
}
.header-menu ul li a:hover {
    color: #00827B;
}
.header-menu ul li:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}
.mast-head a {
    text-decoration: none;
}
.mast-head h1 {
    font-weight: bold;
    font-size: 24px;
    margin: 10px 0;
}
.mast-head .lead {
    font-size: 16px;
    margin: 0;
}